.productGal{
    position: relative;
    margin-top: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.productGal> :nth-child(2){
    text-align: center;
    font-weight: 600;
   
}


.productGal img{
  position: absolute;
  top: 0;
  left: 17rem;
  top: -6rem;
}


.products{
    margin-top: 10rem;
    display: grid;
    grid-template-columns: 25% auto;
    width: 90%;
}

.menu{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    list-style: none;
    font-weight: 600;
    font-size: 1.2rem;
}
.menu  li:hover{
    color: var(--pink);
    cursor: pointer;
}

.list{
    display: grid;
    grid-template-columns: repeat(auto-fill , minmax(200px , 1fr) );
    height: 25rem;
    overflow-y: scroll;
}
.product{
    position: relative;
   
    background: #fff;
padding: 1rem;
width: 14rem;
margin-bottom: 10px;
display: flex;
border-radius: 10px;
cursor: pointer;
overflow: hidden;
  height: 13rem;

  
 
}

.product >img{
    top: 3rem;
    width: 6rem;
    height: 11rem;
    z-index: 7;
    left: 8rem;
    transform: rotate(-20deg);
   
}


@media (max-width:810px){
    .productGal img{
      display: none;

      }
     .product{
        margin-left: 10px;
     }
      .products{
        grid-template-columns: none;
        gap: 1rem;
       
      }
    
     
      .menu{
        flex-direction: row;
      }
}


@media(max-width:500px){
    .menu{
        flex-wrap: wrap;
        width: 100%;
    }
}