.testimonial{
 display: flex;
 justify-content: center;
align-items: center;
    margin-top: 10rem;
    margin-bottom: 5rem;
 
}

.test-c > img{
    width: 30rem;
    display: block;
    justify-self: center;
}


.test-l{
 margin: 6rem auto;
}

.test-l > :nth-child(1){
    font-size: 2rem;
    font-weight:600;
}
.test-r{
    margin: 6rem auto;
    display: flex;
    flex-direction: column;
   }

.test-r > :nth-child(1){
    font-size: 2rem;
    font-weight:600;
}

.profile{
    display: flex;
    flex-direction: column;
  align-items: center;
    justify-content: center;
    background: #fff;
    margin-top: 2rem;
    padding: 1rem;
    gap: 1rem;
    position: relative;
    border-radius: 10px;
    text-align: center;
}

.profile > img{
    width: 3rem;
    position: absolute;
    top: -1.5rem;
   
}

.profile > :nth-child(2){
    margin-top: 1rem;
    font-size: 0.8rem;
    border-bottom: 1px solid #ccc;
}

 .swiper-pagination{
top:11rem ;
width: 12rem;
}

@media(max-width:767px){
    .testimonial{
        display: flex;
        justify-content: center;
       align-items: center;
       text-align: center;
         
        
       }
    .test-l > :nth-child(1){
        font-size: 1rem;
        font-weight:600;
    }
    .test-l > :nth-child(2){
        font-size: 0.7rem;
        font-weight:400;
    }
    .test-c > img{
        width: 70%;
        display: block;
        justify-self: center;
        margin-top: -100px;
      margin-left:50px ;
    }
    .test-r > :nth-child(1){
        font-size: 1rem;
        font-weight:600;
        margin-top: -50px;
    }
}