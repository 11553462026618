.hero-C{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
    
}
.hero-C > img{
    width: 80%;
    position: absolute;
top: -50px;
    z-index: 1;

}

.hero-C > div{
    width: 500px;
    height: 470px;
    border: 0px solid black;
    border-radius: 50%;
  z-index: -1;
    background:rgb(23,185,255);
    position: absolute;
  top: -70px;
}

.hero-C > .Float{
    display: flex;
    gap: 1rem;
}

.Float > i{
    position: absolute;
    left: 0;
    font-size: 2rem;
    color: black;
    bottom: 5.5rem;
    background: #fff;
    padding: .3rem;
    border-radius: 4px;
    box-shadow: var(--shadow2);
   
}

.Float > div{
    display: flex;
    gap: 1rem;
    background: #fff;
  position: absolute;
  padding: 0.6rem;
  border-radius: 3px;
  box-shadow: var(--shadow1);
  right: 0rem;
  bottom: 5rem;

align-items: center;
cursor: pointer;

  
}


.hero-L{
    display: flex;
    flex-direction: column;
    gap: 5rem;
    text-align: start;
}
.hero-L > .top > span{
    font-size: 2rem;
    font-weight: 600;
}
.hero-L > .bottom > span{
    font-size: 2rem;
    font-weight: 600;
}
.hero-R{
    display: flex;
    flex-direction: column;
    gap: 10rem;
    text-align: end;
}

.hero-R > .top > :nth-child(1){
    font-size: 3rem;
    font-weight: 600;
}
.hero-R > .bottom > :nth-child(1){
    font-size: 3rem;
    font-weight: 600;
}




.Slider{
margin: 50px;
position: relative;
}

.Slider >.swiper{
width: 80%;
height: 13rem;
position: unset;
}
.Slider  .swiper-slide{
    background: #fff;
    padding: 1rem;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    display: flex;
    border-radius: 10px;
}




.left-s{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.left-s > :nth-child(2){
    font-size: 2rem;
    font-weight: bold;
}

.left-s > :nth-child(3){
    font-size: 1rem;
    border: 1px solid #ccc;
    padding: .3rem;
    border-radius: 3rem;
    text-align: center;
 
 
}

.name > :nth-child(1){
    font-weight: bold;
    font-size: 1.4rem;
}

.img-p{
    position: absolute;
    right: 0;
    top: 0;
    transform: rotate(-20deg);

    width: 40%;
  }

  @media (max-width:810px){
    .Slider >.swiper{
        width: 100%;
        height: 13rem;
        position: unset;
        }
        .Slider .swiper-button-prev,
        .Slider .swiper-button-next{
                display: none;
        }
    .hero-C > div{
        width: 350px;
        height: 350px;
    
        background:rgb(23,185,255);
        position: absolute;
  top: -20px;
    }
    .hero-C > img{
        width: 100%;
        position: absolute;
        top: -50px;
        z-index: 2;
    
    }
    .Float i{
        display: none;
    }
    .Float div{
        display: none;
    }
    
  }


  @media(max-width:768px){
    .hero-L{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        text-align: start;
      
    }
    .hero-L > .top > span{
        font-size: 1rem;
        font-weight: 600;
    }
    .hero-L > .bottom > span{
        font-size: 1rem;
        font-weight: 600;
    }
    .hero-L > .bottom > p{
        font-size: 0.7rem;
    }

    .hero-R{
        display: flex;
        flex-direction: column;
        gap: 3rem;
        text-align: end;
    }
    .hero-R > .top > :nth-child(1){
        font-size: 1rem;
        font-weight: 600;
    }
    .hero-R > .bottom > :nth-child(1){
        font-size: 1rem;
        font-weight: 600;
    }
     
    .hero-C > div{
        width: 0;
        height: 0;
    
        background:rgb(23,185,255);
        position: absolute;
  top: -20px;
    }
    .hero-C > img{
        width: 50%;
        position: absolute;
 top: -250px;
 right: 0px;
     z-index: 1;
    }
    
  }


  @media (max-width:500px){
    .hero-C > img{
        width: 60%;
        position: absolute;
 top: -230px;
 right: 0px;
     z-index: 1;
    }
    .left-s{
      width: 100%;
      gap: 1;
    }
    
  }