nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
  font-size: 1rem;
}

.header-r{
    display: flex;
    align-items: center;
    display: flex;
    gap: 2rem;
    list-style: none;
    cursor: pointer;
}
.header-r ul{
    display: flex;
    align-items: center;
    display: flex;
    gap: 2rem;
    list-style: none;
    cursor: pointer;
    font-weight: 500;

}
.header-r ul li:hover{
  color: var(--pink);
}
.header-r>:nth-child(2){
    padding: 0.5rem;
    border: none;
    outline: none;
    border-radius: 5px;
}

.header-r>:nth-child(3){
  font-size: 1.5rem;

}
.header-r > ul > :nth-child(6){
    padding: 0.5rem;
    border: none;
    outline: none;
    border-radius: 5px;
    display: none;
  
}
.header-r > ul > :nth-child(7){
 font-size: 1.5rem;
}
.header-l{
    display: flex;
    align-items: center;
    gap: 1rem;
    font-weight: 500;
}
.header-l>:nth-child(1){
    width: 2.5rem;

}

@media(max-width:800px) {
    .header-r{
        position: fixed;
        right: 0rem;
        top: 0rem;
        z-index: 99;
    

    }
    .header-r ul{
        flex-direction: column;
        top: 2rem;
        padding: 2rem;
        text-align: left;
        background: lightcyan;
    }
    input{
        display: none;
    }
}