.footer{
    margin-top: 6rem;
  
    margin-bottom: 3rem;
}
.f > hr{
    border: 1px solid #000;
    margin-top: 6rem;
}
/* .footer hr{

} */

.f-con > div>:nth-child(1){
width: 6rem;
}
.f-con > div> h3{
    font-size: 1.3rem;
 
    }

 .contact i{
 margin-right: 5px;
 font-size: 1.3rem;

 }
 .contact span{
    font-size: 0.8rem;
 }

 .account i{
    margin-right: 5px;
    font-size: 1.3rem;
 }

 .account span{
      
        font-size: 0.8rem;

 }

 .company i{
    margin-right: 5px;
    font-size: 1.3rem;
 }

 
 .company span{
      
    font-size: 0.8rem;

}


@media(max-width:768px){
    .f-con > div>img{
        width: 7rem;
        margin-bottom: 2rem;
      
        }
        .contact h3{
    
        margin-bottom: 1rem;
        }
        .account h3{
            margin-top: 2rem;
        }
        .company h3{
            margin-top: 2rem;
        }
     
}
