.compare{
    margin-top: 100px;
}

.wrapper{
    width: 100%;
}

.left-c> div>:nth-child(1){
    
    font-weight: 700;
    margin-bottom: 20px;
}
.left-c{
    position: relative;
    height: 19rem;
   
}

.left-c> div>:nth-child(2){

 
    font-size: 1.3rem;
}
.left-c> div>:nth-child(3){
    font-weight: 700;
    margin-bottom: 20px;
}

.left-c> div>:nth-child(4){
  width: 40%;
  position: absolute;
  left: 0;
  bottom: 90px;
}